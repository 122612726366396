<template>
  <div>

    <section>
      <article style="height: 100vh; display: flex; align-items:center; justify-content: center;">
          <div>
            <h1>The xperience.</h1>
            <h1>The xperience.</h1>
            <h1>The xperience.</h1>
            <h1>The xperience.</h1>
          </div>
      </article>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  methods: {
    verifyPhoneNUmber() {
      this.$router.push({ path: `verify-phone-number` });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  * {
    padding: 0;
    margin: 0;
    font-family: "Roboto", sans-serif;
  }
</style>
